export default function Youtube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 0a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm23.227 11.184H12.773C10.14 11.184 8 13.33 8 15.97v8.058c0 2.64 2.14 4.787 4.773 4.787h14.454c2.632 0 4.773-2.148 4.773-4.787V15.97c0-2.64-2.14-4.787-4.773-4.787Zm-2.887 8.639-6.787-3.975a.49.49 0 0 0-.737.423v7.949a.491.491 0 0 0 .738.423l6.786-3.974a.49.49 0 0 0 0-.846Z"
        clipRule="evenodd"
      />
    </svg>
  );
}