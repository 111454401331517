export default function Discord(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 0a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm29.88 26.455c.583-5.943-.978-11.105-4.14-15.681a.07.07 0 0 0-.037-.033 23.36 23.36 0 0 0-5.7-1.74.088.088 0 0 0-.09.043c-.247.43-.52.993-.71 1.434a21.662 21.662 0 0 0-6.402 0 14.49 14.49 0 0 0-.72-1.434.09.09 0 0 0-.092-.043c-2 .34-3.914.933-5.7 1.74a.081.081 0 0 0-.037.032c-3.63 5.336-4.624 10.54-4.136 15.68.002.026.016.05.036.065a23.365 23.365 0 0 0 6.992 3.478.092.092 0 0 0 .098-.031 16.317 16.317 0 0 0 1.43-2.29.087.087 0 0 0-.048-.121 15.385 15.385 0 0 1-2.184-1.025.087.087 0 0 1-.01-.146c.147-.108.294-.22.434-.335a.089.089 0 0 1 .091-.012c4.582 2.059 9.543 2.059 14.072 0a.087.087 0 0 1 .091.011c.14.114.287.228.435.336a.087.087 0 0 1-.008.146c-.697.402-1.422.74-2.185 1.024a.088.088 0 0 0-.047.122c.42.802.9 1.565 1.429 2.288a.09.09 0 0 0 .098.033 23.29 23.29 0 0 0 7.003-3.478.089.089 0 0 0 .036-.063ZM15.355 17.77c1.413 0 2.539 1.258 2.517 2.777 0 1.53-1.115 2.777-2.517 2.777-1.379 0-2.516-1.246-2.516-2.777 0-1.53 1.115-2.777 2.516-2.777Zm9.304 0c1.413 0 2.539 1.258 2.517 2.777 0 1.53-1.104 2.777-2.517 2.777-1.38 0-2.516-1.246-2.516-2.777 0-1.53 1.115-2.777 2.517-2.777Z"
        clipRule="evenodd"
      />
    </svg>
  );
}