import { FunctionComponent, memo, ReactNode, useCallback } from 'react';
import './button.scss';
import { Link } from 'react-router-dom';

export type ButtonProps = {
  type?: any;
  text?: any;
  href?: any;
  icon?: any;
  children?: ReactNode;
  icon_direction?: any;
  className?: any;
  onClick?: any;
  isOutterHref?: boolean;
  disabled?: any;
  currencyComponent?: ReactNode;
  state?: any;
  is_icon?: boolean;
  openInNewTab?: boolean;
  onMouseEnter?: any;
  playAudio?: any;
};

export const Button: FunctionComponent<ButtonProps> = memo(
  ({
    type,
    text,
    href,
    icon = null,
    icon_direction = 'left',
    className,
    onClick,
    is_icon = false,
    disabled,
    isOutterHref = true,
    currencyComponent,
    children,
    state,
    openInNewTab,
  }: ButtonProps) => {
    // Используем useCallback для мемоизации функции
    const getClassNames = useCallback((classNames) => {
      if (!classNames) return '';
      //TO DO: исправить прочекать ререндеры
      //console.log('classnames', classNames);
      return classNames;
    }, []); // Пустой массив зависимостей, чтобы функция создавалась только один раз

    const iconClass = is_icon ? 'btn_icon' : '';
    const buttonClasses = `btn1 ${iconClass} ${getClassNames(className)}`;

    if (type === 'button') {
      return (
        <button
          className={buttonClasses}
          onClick={onClick}
          type="button"
          disabled={disabled}
        >
          {icon_direction === 'left' && icon}
          {text && (
            <span>
              {text} {currencyComponent}{' '}
            </span>
          )}
          {children}
          {icon_direction === 'right' && icon}
        </button>
      );
    }
    if (isOutterHref) {
      return (
        <a
          href={href}
          className={buttonClasses}
          onClick={onClick}
          target={openInNewTab ? '_blank' : undefined}
          rel={openInNewTab ? 'noopener noreferrer' : undefined}
        >
          {icon_direction === 'left' && icon}
          {text && <span>{text}</span>}
          {children}
          {icon_direction === 'right' && icon}
        </a>
      );
    } else {
      return (
        <Link
          to={href}
          className={buttonClasses}
          onClick={onClick}
          state={state}
        >
          {icon_direction === 'left' && icon}
          {text && <span>{text}</span>}
          {children}
          {icon_direction === 'right' && icon}
        </Link>
      );
    }
  },
);
