import {
  MouseEventHandler,
  FunctionComponent,
  useEffect,
  ReactNode,
  useState,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  memo,
  forwardRef,
} from 'react';
import classNames from 'classnames';
import { Loader } from '../../../public/frontend/components/Loader';
import { useHover } from '~public/frontend/components/hooks/useHover';
type Loading = number | boolean;

export type ButtonProps = {
  icon?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  loading?: boolean | { delay?: number };
  children?: ReactNode;
  isDemo?: boolean;
  isDemoDisabled?: boolean;
  onFastEndBtnClick?: any;
  isFastEnd?: any;
} & Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'type' | 'onClick'
>;

export const Button: FunctionComponent<ButtonProps> = memo(
  forwardRef((props, ref) => {
    const {
      loading = false,
      disabled,
      className,
      children,
      icon,
      ...rest
    } = props;
    const [innerLoading, setLoading] = useState<Loading>(!!loading);

    const loadingOrDelay: Loading =
      typeof loading === 'boolean' ? loading : loading?.delay || true;

    useEffect(() => {
      let delayTimer: number | null = null;

      if (typeof loadingOrDelay === 'number') {
        delayTimer = window.setTimeout(() => {
          delayTimer = null;
          setLoading(loadingOrDelay);
        }, loadingOrDelay);
      } else {
        setLoading(loadingOrDelay);
      }

      return () => {
        if (delayTimer) {
          // in order to not perform a React state update on an unmounted component
          // and clear timer after 'loadingOrDelay' updated.
          window.clearTimeout(delayTimer);
          delayTimer = null;
        }
      };
    }, [loadingOrDelay]);

    const handleClick = (
      e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
    ) => {
      const { onClick } = props;
      // https://github.com/ant-design/ant-design/issues/30207
      if (innerLoading || disabled) {
        e.preventDefault();
        return;
      }
      (onClick as MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>)?.(
        e,
      );
    };

    return (
      <button
        ref={ref}
        {...rest}
        className={classNames(['button', className])}
        onClick={handleClick}
        disabled={(typeof loading === 'boolean' && loading) || disabled}
      >
        {loading && <Loader fon={false} />}
        {children}
      </button>
    );
  }),
);
