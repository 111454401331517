import { FunctionComponent } from 'react';
import './inputWithIcon.scss';

/**
 * Компонент InputWithIcon для отображения поля ввода с иконкой
 * @param {Object} props - Свойства компонента
 * @param {React.ReactNode} props.icon - Иконка для отображения
 * @param {string} props.iconPosition - Позиция иконки ("left" или "right")
 * @param {string} props.placeholder - Плейсхолдер
 * @param {boolean} props.required - Обязательное ли поле
 * @param {string} props.value - Значение поля
 * @param {function} props.onChange - Функция обратного вызова при изменении
 * @param {boolean} props.error - Флаг ошибки
 * @param {string} props.className - Дополнительный класс для контейнера
 */
const InputWithIcon: FunctionComponent<any> = ({
  icon,
  iconPosition = 'left',
  placeholder,
  required = false,
  value,
  onChange,
  error = false,
  errorMessage = '',
  className = '',
  isPromoBadge = false,
  promoPercentText = '',
  isDisabled = false,
}) => {
  // Удаляем звездочку из плейсхолдера, если она там есть
  const cleanPlaceholder = placeholder.replace(' *', '');

  return (
    <div
      className={`input_with_icon ${error ? 'input_error' : ''} ${className}`}
    >
      {error && <div className="error_message">{errorMessage}</div>}
      {icon && iconPosition === 'left' && (
        <span className="input_icon">{icon}</span>
      )}
      <input
        type="text"
        className="input_field"
        placeholder={cleanPlaceholder}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
      />
      {required && <span className="required_star">*</span>}
      {value && isPromoBadge && (
        <span className="promo_badge">{promoPercentText || '+5%'}</span>
      )}
      {icon && iconPosition === 'right' && (
        <span className={`input_icon input_icon_right`}>{icon}</span>
      )}
    </div>
  );
};

export default InputWithIcon;
