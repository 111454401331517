export default function Twitch(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 0a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm2 12 6-5h22v15L24 32h-5l-6 5v-5H6V12Zm21 13 5-4V9H13v16h5v4l4-4h5Zm-1-12h2v6h-2v-6Zm-5 0h-2v6h2v-6Z"
        clipRule="evenodd"
      />
    </svg>
  );
}