export default function X(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 0a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm4 9.686h7.535l5.232 6.837 6-6.837h3.628l-7.918 9.07L32 31.174h-7.535l-5.75-7.503-6.599 7.503H8.488l8.518-9.734L8 9.686Zm4.231 2.023h2.093l13.21 17.303h-2.092l-13.21-17.303Z"
        clipRule="evenodd"
      />
    </svg>
  );
}