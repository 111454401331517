export default function TikTok(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 0a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm25.174 11.072c.19.125.388.24.59.344 1.318.657 2.605.857 3.239.788v5.115s-1.81-.075-3.152-.43c-1.87-.502-3.07-1.27-3.07-1.27s-.833-.55-.895-.588V25.6c0 .587-.156 2.057-.622 3.283a9.904 9.904 0 0 1-1.728 2.87s-1.15 1.426-3.17 2.383c-1.683.798-3.181.841-3.776.859l-.127.004s-2.766.111-5.26-1.584l-.013-.012v.012a10.47 10.47 0 0 1-2.232-2.105c-.79-1.007-1.274-2.202-1.399-2.552v-.012c-.198-.595-.615-2.033-.553-3.42.1-2.446.92-3.953 1.138-4.328a10.111 10.111 0 0 1 2.206-2.72 9.571 9.571 0 0 1 7.375-2.158l-.006 5.246a4.307 4.307 0 0 0-1.338-.212c-2.4 0-4.347 1.957-4.347 4.375s1.946 4.375 4.347 4.375a4.3 4.3 0 0 0 2.132-.563 4.375 4.375 0 0 0 2.2-3.472v-.011c.004-.015.004-.03.004-.045l.002-.032a.93.93 0 0 0 .003-.065c.011-.261.011-.526.011-.793V5h5.153c-.008 0-.062.494.068 1.257h-.006c.155.92.578 2.232 1.709 3.527.452.489.961.921 1.517 1.288Z"
        clipRule="evenodd"
      />
    </svg>
  );
}