import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useSubscription, gql } from '@apollo/client';

import {
  FRAGMENT_BATTLE_PASS_USER_QUEST_FILEDS,
  FRAGMENT_EXTERNAL_ACCOUNT_FILEDS,
  useConfig,
  useGetStatistics,
} from '~components/hooks';
import {
  Live,
  TopMenu,
  Languages,
  SiteSound,
  Feedback,
  Search,
  Profile,
  Menu,
} from '~components/header';

import {
  FRAGMENT_ACTIVE_MODAL_FILEDS,
  GET_ACTIVE_MODAL,
} from '~components/hooks';
import { useProfile } from '~components/profile/hooks';

import ErrorBoundary from '../error/ErrorBoundary';

import Logo from '../../scss/images/logo.svg';

import { GET_HISTORY } from '~components/profile/items';
import { SELL_ALL_ITEMS } from '~components/profile/SellAllItems';
import { ActiveGames, BattlePassUserQuest } from 'graphql';
import { AuthBlock } from '~frontend/components/AuthBlock';
import { useClickAway, useToggle, useWindowScroll } from 'react-use';
import BottomMenuMobile from '~frontend/components/header/BottomMenuMobile';

import './Header.scss';
import { HeaderTop } from '../../components/header/header-top/headerTop';
import HeaderBottom from '~frontend/components/header/header-bottom/headerBottom';

const Online = () => {
  const { data: { getStatistics } = {} } = useGetStatistics();

  return <>{getStatistics?.online}</>;
};

const Header: FunctionComponent = () => {
  const ref = useRef();
  const location = useLocation();
  const getConfig = useConfig();
  const [mobileIsAcitve, toogleMobile] = useToggle(false);
  const { y } = useWindowScroll();
  const [active, setActive] = useState(false);
  const { t } = useTranslation();
  const getProfile = useProfile();
  //const location = useLocation();
  const isPaymentPage = location.pathname.includes('/pay');

  useClickAway(ref, () => {
    toogleMobile(false);
  });

  useEffect(() => {
    toogleMobile(false);
  }, [location.pathname, toogleMobile]);

  useSubscription(
    gql`
      subscription updateActiveModal {
        updateActiveModal {
          ...ActiveModalFields
        }
      }

      ${FRAGMENT_ACTIVE_MODAL_FILEDS}
    `,
    {
      onData: ({ client, data }) => {
        client.cache.writeQuery({
          query: GET_ACTIVE_MODAL,
          data: {
            getActiveModal: data?.data?.updateActiveModal,
          },
        });
      },
    },
  );

  useSubscription(
    gql`
      subscription updateBattlePassLevel {
        updateBattlePassLevel {
          id
          isOpen
        }
      }
    `,
  );

  useSubscription(
    gql`
      subscription updateProfile {
        updateProfile {
          id
          userName
          avatar
          money
          battlePassCoins
          coins
          referralMoney
          getWheelStep
          lastWheelGame
          joinedToGroup
          joinedToGroupTelegram
          getSteam @client
          getTelegram @client
          getVk @client
          deposit24Hour
          lastFreeGameDate
          deposit24HourTtl
          joinedToTwitter
          joinedToFacebook
        }
      }
    `,
  );

  useSubscription(
    gql`
      subscription updateProfileExternalAccount {
        updateProfileExternalAccount {
          id
          getExternalAccounts {
            ...ExternalAccountFields
          }
          getSteam @client
          getVk @client
          getTelegram @client
        }
      }

      ${FRAGMENT_EXTERNAL_ACCOUNT_FILEDS}
    `,
  );

  useSubscription(
    gql`
      subscription updateExternalAccount {
        updateExternalAccount {
          ...ExternalAccountFields
        }
      }

      ${FRAGMENT_EXTERNAL_ACCOUNT_FILEDS}
    `,
  );

  useSubscription<{ createBattlePassUserQuest: BattlePassUserQuest }>(
    gql`
      subscription createBattlePassUserQuest {
        createBattlePassUserQuest {
          ...BattlePassUserQuestFields
        }
      }

      ${FRAGMENT_BATTLE_PASS_USER_QUEST_FILEDS}
    `,
    {
      onData: ({ client, data: { data } }) => {
        if (!data?.createBattlePassUserQuest) {
          return false;
        }

        client.cache.writeFragment({
          id: `BattlePassQuest:${data.createBattlePassUserQuest.battlePassQuestId}`,
          fragment: gql`
            fragment UpdateBattlePassQuest on BattlePassQuest {
              getBattlePassUserQuest {
                ...BattlePassUserQuestFields
              }
            }
            ${FRAGMENT_BATTLE_PASS_USER_QUEST_FILEDS}
          `,
          fragmentName: 'UpdateBattlePassQuest',
          data: {
            getBattlePassUserQuest: data?.createBattlePassUserQuest,
          },
        });
      },
    },
  );

  useSubscription(
    gql`
      subscription updateBattlePassUserQuest {
        updateBattlePassUserQuest {
          ...BattlePassUserQuestFields
        }
      }

      ${FRAGMENT_BATTLE_PASS_USER_QUEST_FILEDS}
    `,
  );

  useSubscription(
    gql`
      subscription addGame {
        addGame {
          ...GameFields
        }
      }

      ${GET_HISTORY.fragments.games}
    `,
    {
      onData: async ({
        client,
        data: {
          data: { addGame },
        },
      }) => {
        if (!addGame) {
          return null;
        }

        const data = client.readQuery<{
          getHistory: ActiveGames;
        }>({
          query: GET_HISTORY.query,
          variables: {
            input: {
              userId: getProfile.id,
              isAwait: false,
            },
          },
        });

        const dataSellitems = client.readQuery({
          query: SELL_ALL_ITEMS,
          variables: {
            userId: getProfile.id,
          },
        });

        const getHistory = data?.getHistory;

        if (getHistory && _.find(getHistory?.getGames, ['id', addGame?.id])) {
          return;
        }

        const getStatsSellitems = dataSellitems?.getStats;

        if (getStatsSellitems && addGame.caseId !== 11) {
          const price =
            addGame?.status === 1 ? addGame?.priceSell : addGame?.price;
          client.cache.writeQuery({
            query: SELL_ALL_ITEMS,
            data: {
              getStats: {
                sumItems: getStatsSellitems.sumItems + price,
                sumItemsOld: getStatsSellitems.sumItemsOld + addGame.price,
              },
            },
            variables: { userId: getProfile.id },
          });
        }

        if (getHistory) {
          client.writeQuery({
            query: GET_HISTORY.query,
            data: {
              getHistory: {
                count: getHistory.count + 1,
                getGames: _.orderBy(
                  [addGame, ..._.toArray(getHistory?.getGames)],
                  ['id'],
                  ['desc'],
                ),
              },
            },
            variables: {
              input: {
                userId: getProfile.id,
                isAwait: false,
              },
            },
          });
        }
      },
    },
  );

  useEffect(() => {
    if (y > 100) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [y]);

  if (isPaymentPage) return null;

  return (
    <>
      <div className="header">
        <HeaderTop />

        {/* <HeaderBottom /> */}
      </div>
      <HeaderBottom />
    </>
    // <header className="header">
    //   <div className="top-drop-name">
    //     <span>Top drop</span>
    //   </div>
    //   <div className="top-drop-title">
    //     <div className="top-drop-title-wrap">
    //       <span>live</span>
    //       <span>
    //         <span className="icon material-icons" data-icon="&#xe7ef;" />
    //         <Online /> {t('Online')}
    //       </span>
    //     </div>
    //   </div>
    //   <ErrorBoundary>
    //     <Live />
    //   </ErrorBoundary>
    //   <BottomMenuMobile />
    //   <div className={`header__wrap ${active && 'fixed'}`}>
    //     <Link
    //       className="logo main_logo"
    //       to="/"
    //       data-audio="3.mp3"
    //       data-volume="0.15"
    //     >
    //       <img src={Logo} alt="logo" />
    //     </Link>
    //     <div className="header__controls">
    //       <Feedback />
    //       <Languages />
    //       <SiteSound />
    //     </div>
    //     <ErrorBoundary>
    //       <TopMenu />
    //     </ErrorBoundary>
    //     <ErrorBoundary>
    //       <Search />
    //     </ErrorBoundary>
    //     {getProfile ? <Profile /> : <AuthBlock authSocial />}
    //     <div className={`mobile-menu ${mobileIsAcitve && 'active'}`} ref={ref}>
    //       <div
    //         className={`mobile-menu-btn ${mobileIsAcitve && 'active'}`}
    //         onClick={toogleMobile}
    //       >
    //         <svg
    //           width="48"
    //           height="48"
    //           viewBox="0 0 48 48"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             d="M10.5 15H37.5V18.0234H10.5V15ZM10.5 25.4766V22.5234H37.5V25.4766H10.5ZM10.5 33V29.9766H37.5V33H10.5Z"
    //             fill="#ffc600"
    //           />
    //         </svg>
    //       </div>
    //       <div className="mobile-menu-nav">
    //         <div className="header-profile-user__dropdown-items">
    //           <Menu />
    //         </div>
    //         <Search />
    //         <div className="header__controls">
    //           <Feedback />
    //           <Languages />
    //           <SiteSound />
    //         </div>
    //         <div className="soc-footer">
    //           <a
    //             className="discord"
    //             href={getConfig?.seo.discord}
    //             rel="noopener noreferrer nofollow"
    //             target="_blank"
    //           ></a>
    //           {getConfig?.hostname !== 'cscase.com' && (
    //             <>
    //               <a
    //                 className="telegram"
    //                 href={getConfig?.seo.telegram}
    //                 rel="noopener noreferrer nofollow"
    //                 target="_blank"
    //               ></a>
    //               <a
    //                 className="vk"
    //                 href={getConfig?.seo.vk}
    //                 rel="noopener noreferrer nofollow"
    //                 target="_blank"
    //               ></a>
    //             </>
    //           )}
    //           <a
    //             className="instagram"
    //             href={getConfig?.seo.instagram}
    //             rel="noopener noreferrer nofollow"
    //             target="_blank"
    //           ></a>
    //           <a
    //             className="fb"
    //             href={getConfig?.seo.fb}
    //             rel="noopener noreferrer nofollow"
    //             target="_blank"
    //           ></a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </header>
  );
};

export default Header;
