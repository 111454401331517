export default function Telegram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 0a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm21.315 29.72-5.518-4.041-.01.005-2.662 2.548a1.399 1.399 0 0 1-1.112.538l.391-5.582 10.23-9.184c.13-.114.182-.232.154-.306-.028-.074-.124-.118-.257-.118-.21.01-.412.08-.584.2l-12.64 7.908-5.448-1.69c-.542-.17-.859-.442-.869-.742-.012-.356.396-.72 1.118-1l21.283-8.158c.172-.064.353-.098.536-.1a.987.987 0 0 1 .8.358 1.727 1.727 0 0 1 .2 1.467l-3.623 16.969c-.17.79-.552 1.207-1.1 1.207a1.868 1.868 0 0 1-.889-.278Z"
        clipRule="evenodd"
      />
    </svg>
  );
}